import React from "react"
function ContactButton() {
  return (
    <>
      <a
        href="https://twitter.com/philz1337x"
        target="_blank"
        rel="noopener noreferrer"
        className="fixed bottom-0 z-1000 left-0 text-white bg-[#1da1f2] hover:bg-[#1da1f2]/90 focus:ring-4 focus:outline-none focus:ring-[#1da1f2]/50 font-medium rounded-tr-lg text-sm px-3 py-1 text-center inline-flex items-center "
      >
        @philz1337x
      </a>
    </>
  )
}

export default ContactButton
