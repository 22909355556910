import React, { useEffect, useState } from "react"
import { useAuth } from "../../context/AuthContext"
import { firestore, serverTimestamp } from "../../firebase"
import { telegram } from "../../utils/telegram"
import Recent from "./Recent"
import Popup from "../Sign/Popup"
import { IconInfo } from "../../components/Icons"
import { useHistory } from "react-router-dom"

export default function Create() {
  const { user } = useAuth()

  const [isLoading, setisLoading] = useState(false)
  const [error, setError] = useState(false)
  const [resolution, setResolution] = useState("1720x3728")
  const [seed, setSeed] = useState("fixed")
  const [prompt, setPrompt] = useState("")
  const [time, setTime] = useState("")
  const [promptToolTip, setPromptToolTip] = useState(false)
  const [seedToolTip, setSeedToolTip] = useState(false)
  const [creditCost, setCreditCost] = useState(5)
  const [showPopup, setShowPopup] = useState(false)

  const history = useHistory()

  useEffect(() => {
    if (resolution) {
      if (resolution === "1920x1080") {
        setCreditCost(5)
      } else if (resolution === "2560x1440") {
        setCreditCost(5)
      } else if (resolution === "1720x3728") {
        setCreditCost(10)
      } else if (resolution === "3840x2160") {
        setCreditCost(15)
      } else if (resolution === "5120x2880") {
        setCreditCost(25)
      }
    } else {
      setCreditCost(false)
    }
  }, [resolution])

  useEffect(() => {
    setTime(creditCost * 3)
  }, [creditCost])

  const createImageDocument = async () => {
    setisLoading(true)
    setError(null)
    if (!user) {
      setError("Register to get credits.")
      setisLoading(false)
      return
    }

    if (user.balance <= 0) {
      history.push("/pricing")
      setError("Not enough balance!")
      setisLoading(false)
      return
    }

    const credits = user.balance - creditCost
    try {
      await firestore.collection("users").doc(user.id).update({
        balance: credits,
      })
    } catch (error) {
      telegram("frontend, Create, createImageDocument, error: " + error.message)
      setisLoading(false)
      setError("Error while updating credits!")
      return null
    }

    const imageData = {
      userId: user.id,
      createdAt: serverTimestamp(),
      status: "created",
      prompt: prompt,
      creditCost: creditCost,
      resolution: resolution,
      seedMode: seed,
    }

    try {
      const imagesCollectionRef = firestore
        .collection("users")
        .doc(user.id)
        .collection("images")

      const docRef = await imagesCollectionRef.add(imageData)

      setisLoading(false)
      return docRef.id
    } catch (error) {
      telegram("frontend, Create, createImageDocument, error: " + error.message)
      setisLoading(false)
      return null
    }
  }

  return (
    <div className="w-full h-full flex flex-col items-center bg-bg-color text-white flex-grow">
      <div className="h-full px-6 w-full flex lg:flex-row flex-col">
        <div className="w-full lg:h-full sm:min-w-fit lg:max-w-xs lg:mr-1 lg:pr-4 lg:border-r border-neutral-700 pt-6">
          <div className="flex justify-center py-1 bg-neutral-800 border border-neutral-700 rounded-lg">
            <img alt="credits" className="w-6" src="/credits.png"></img>
            {user ? user.balance : "0"}
          </div>

          <div>
            <div className="flex items-center mt-6">
              <p className="font-bold my-2 mr-2">Prompt</p>
              <div
                className="cursor-pointer"
                onMouseEnter={() => setPromptToolTip(true)}
                onMouseLeave={() => setPromptToolTip(false)}
              >
                <IconInfo />
              </div>
            </div>
            {promptToolTip && (
              <div className="absolute z-10 w-52 inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-700 rounded-lg shadow-sm">
                Describe what you want to see in your images. Use simple words
                like "Swiss Alps."
                <div className="tooltip-arrow" data-popper-arrow></div>
              </div>
            )}

            <textarea
              id="prompt"
              type="text"
              rows="3"
              className="bg-neutral-800 w-full border border-neutral-700 text-white text-sm rounded-lg block p-2.5"
              value={prompt}
              onChange={(e) => setPrompt(e.target.value)}
            />
          </div>

          <div>
            <div className="flex items-center">
              <p className="font-bold my-2 mr-2 mt-4">Resolution</p>
            </div>
            <select
              className="bg-neutral-800 w-full border border-neutral-700 text-white text-sm rounded-lg block p-2.5"
              value={resolution}
              onChange={(event) => setResolution(event.target.value)}
            >
              <option value="1720x3728">Smartphones (1720x3728)</option>
              <option value="1920x1080">Full HD (1920x1080)</option>
              <option value="2560x1440">Widescreen (2560x1440)</option>
              <option value="3840x2160">4K (3840x2160)</option>
              <option value="5120x2880">5K (5120x2880)</option>
            </select>
          </div>

          <div>
            <div className="flex items-center mt-4">
              <p className="font-bold my-2 mr-2">Seed</p>
              <div
                className="cursor-pointer"
                onMouseEnter={() => setSeedToolTip(true)}
                onMouseLeave={() => setSeedToolTip(false)}
              >
                <IconInfo />
              </div>
            </div>
            {seedToolTip && (
              <div className="absolute z-10 w-52 inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-700 rounded-lg shadow-sm">
                Decide if you want to get a reproducible image or a random one.
                With random, you can create unlimited images for one prompt.
                <div className="tooltip-arrow" data-popper-arrow></div>
              </div>
            )}

            <select
              className="bg-neutral-800 w-full border border-neutral-700 text-white text-sm rounded-lg block p-2.5"
              value={seed}
              onChange={(event) => setSeed(event.target.value)}
            >
              <option value="fixed">Fixed</option>
              <option value="random">Random</option>
            </select>
          </div>

          <button
            type="submit"
            disabled={isLoading}
            onClick={user ? createImageDocument : () => setShowPopup(true)}
            className="mt-8 w-full h-12 rounded-lg font-bold text-white bg-gradient-to-r from-cyan-500 to-blue-500 via-purple-500 hover:from-black hover:border hover:border-white hover:bg-black hover:text-white background-animate"
          >
            <div className="flex flex-row place-content-center">
              {isLoading ? (
                <div className="flex justify-center items-center">
                  <p className="pr-2">Loading</p>
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5  text-white animate-spin  fill-blue-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                </div>
              ) : (
                <span>Create Wallpaper ~{time}s</span>
              )}
            </div>
          </button>

          <div className="flex flex-col items-center justify-center my-4 py-1 bg-neutral-800 border border-neutral-700 rounded-lg text-sm text-neutral-400">
            <p></p>
            {creditCost ? (
              <p>Will cost {creditCost} credits</p>
            ) : (
              <p className="text-red-800">Error</p>
            )}
          </div>

          {error && (
            <div
              class="flex items-center p-4 mb-4 text-sm border rounded-lg bg-gray-800 text-red-400 border-red-800"
              role="alert"
            >
              <div> {error}</div>
            </div>
          )}
        </div>

        <div className="h-full lg:ml-4 mt-6">
          <Recent />
        </div>
      </div>
      <Popup show={showPopup} onClose={() => setShowPopup(false)} />
    </div>
  )
}
