import React, { useState, useEffect } from "react"
import { useAuth } from "../../context/AuthContext"

import Image from "../../components/Image"
import LoadingImage from "../../components/LoadingImage"

export default function Recent({ setOptions }) {
  const { images } = useAuth()
  const [displayCount, setDisplayCount] = useState(2)

  const checkInitialLoad = () => {
    if (window.innerHeight >= document.body.offsetHeight) {
      setDisplayCount((prev) => prev + 4)
    }
  }

  const handleScroll = () => {
    if (
      window.innerHeight + window.scrollY >=
      document.body.offsetHeight - 500
    ) {
      setDisplayCount((prev) => prev + 4)
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    checkInitialLoad()

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  const dummyImages = [
    // {
    //   id: "dummy0",
    //   status: "done",
    //   prompt: "Many Purple marbles",
    //   resolution: "1720x3728",
    //   outputImage:
    //     "https://storage.googleapis.com/wallpaper-e9519.appspot.com/outputs/gji2mXUZxjgIeBLBqAUWtaoGNPe2-3vrFHwOWzWIxsfhbtVFF.jpg",
    // },
    {
      id: "dummy1",
      status: "done",
      prompt: "House in the mountains",
      resolution: "1920x1080",
      outputImage:
        "https://storage.googleapis.com/wallpaper-e9519.appspot.com/outputs/gji2mXUZxjgIeBLBqAUWtaoGNPe2-NRiIm1JnZaY33nlmxfFR.jpg",
    },
    {
      id: "dummy2",
      status: "done",
      prompt: "Koh Phangan, Thailand",
      resolution: "5120x2880",
      outputImage:
        "https://storage.googleapis.com/wallpaper-e9519.appspot.com/outputs/gji2mXUZxjgIeBLBqAUWtaoGNPe2-mGAqoS0fXSjpI1eoarBK.jpg",
    },
    {
      id: "dummy3",
      status: "done",
      prompt: "Cornfield",
      resolution: "1920x1080",
      outputImage:
        "https://storage.googleapis.com/wallpaper-e9519.appspot.com/outputs/gji2mXUZxjgIeBLBqAUWtaoGNPe2-ihhBPlzV8I6Yrnl8q24s.jpg",
    },
    {
      id: "dummy4",
      status: "done",
      prompt: "Ancient Temple, modern Swimmingpool",
      resolution: "5120x2880",
      outputImage:
        "https://storage.googleapis.com/wallpaper-e9519.appspot.com/outputs/gji2mXUZxjgIeBLBqAUWtaoGNPe2-fScMHG6DmKegxDdjeHQF.jpg",
    },
  ]

  const displayImages = images && images.length > 0 ? images : dummyImages

  return (
    <div className="h-full">
      {displayImages && (
        <div className="grid grid-cols-1 ##2xl:grid-cols-2 gap-4">
          {displayImages
            .slice(0, displayCount)
            .map((image) =>
              image.status === "done" ? (
                <Image key={image.id} image={image} setOptions={setOptions} />
              ) : (
                (image.status === "processing" ||
                  image.status === "created") && (
                  <LoadingImage key={image.id} image={image} />
                )
              )
            )}
        </div>
      )}
    </div>
  )
}
