import React from "react"
import "./assets/App.css"
import Create from "./pages/Dashboard/Create"
import Header from "./pages/Landingpage/Header"
import Banner from "./pages/Landingpage/Banner"
import Register from "./pages/Sign/Register"
import Login from "./pages/Sign/Login"
import { BrowserRouter, Switch, Route } from "react-router-dom"
import ForgotPassword from "./pages/Sign/ForgotPassword"
import { AuthProvider } from "./context/AuthContext"
import PrivateRoute from "./components/PrivateRoute"
import Pricing from "./components/Pricing"
import Footer from "./pages/Landingpage/Footer"
import ContactButton from "./components/ContactButton"

function App() {
  return (
    <>
      <AuthProvider>
        <BrowserRouter>
          <div className="flex flex-col min-h-screen bg-bg-color">
            <Switch>
              <Route path="/login">
                <Login />
              </Route>
              <Route path="/register">
                <Register />
              </Route>
              <Route path="/forgot-password">
                <ForgotPassword />
              </Route>
              <PrivateRoute path="/admin/">Admin Area</PrivateRoute>
              <Route>
                <Header />
                <Route exact path="/">
                  <Banner />
                  <Create />
                </Route>

                <Route path="/pricing">
                  <Pricing />
                </Route>
                <Footer />
              </Route>
            </Switch>
          </div>
          <ContactButton />
        </BrowserRouter>
      </AuthProvider>
    </>
  )
}

export default App
