function Image({ image, showMeta = true }) {
  const tags = [
    {
      label: "Resolution",
      value: image?.resolution ?? 0,
    },
  ]
  return (
    <div className="relative group">
      <>
        <div className="image-slider relative">
          <div className="absolute z-10 flex w-full bottom-0  justify-end pointer-events-none p-4">
            {/* Bottom right contents */}
            <div className="flex flex-col items-end justify-end ml-10 pointer-events-auto">
              {/* Prompt */}
              {showMeta && image.prompt && (
                <div className="flex max-w-md items-start px-3 py-1 bg-black/70 rounded-xl mb-1.5">
                  <span className="text-xs text-white/50 mr-1.5">Prompt</span>
                  <span className="text-xs font-bold text-white italic text-left">
                    {image.prompt}
                  </span>
                </div>
              )}

              {/* Tags */}
              <div className="flex">
                {showMeta &&
                  tags?.map((tag, index) => (
                    <div
                      key={index}
                      className="flex items-center px-3 py-1 bg-black/70 rounded-xl mr-2 last:mr-0"
                    >
                      <span className="text-xs text-neutral-400 mr-1.5">
                        {tag.label}
                      </span>
                      <span className="text-xs font-bold text-white">
                        {tag.value}
                      </span>
                    </div>
                  ))}
              </div>
            </div>
          </div>

          <figure className="relative">
            <img
              alt={"wallpaper of " + image?.prompt}
              src={image?.outputImage}
              className="w-full rounded-lg"
            />
          </figure>
        </div>
      </>
    </div>
  )
}

export default Image
