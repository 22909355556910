import React from "react"
import { useAuth } from "../context/AuthContext"

export default function Pricing() {
  const { user } = useAuth()
  const referrer = sessionStorage.getItem("referrer")

  return (
    <div className="my-24">
      {user?.email && (
        <div>
          <stripe-pricing-table
            data-rewardful
            customer-email={user?.email}
            client-reference-id={referrer}
            pricing-table-id="prctbl_1On0jGF44YbTqOHsXfUBy3qp"
            publishable-key="pk_live_51OmtXRF44YbTqOHsuDlwBTlXKbV1YpUZPBmTpxDj0G9JEYuin7IoqCGhLiepDRtLlqf3iKra95pSKWm1vFAWS2ay00fVvOYMH1"
          ></stripe-pricing-table>
          <h1 className="my-12 mt-24 text-3xl text-white font-semibold text-center">
            One-time purchase
          </h1>
          <script
            async
            src="https://js.stripe.com/v3/pricing-table.js"
          ></script>
          <stripe-pricing-table
            data-rewardful
            customer-email={user?.email}
            client-reference-id={referrer}
            pricing-table-id="prctbl_1POidcF44YbTqOHsFnyxpp09"
            publishable-key="pk_live_51OmtXRF44YbTqOHsuDlwBTlXKbV1YpUZPBmTpxDj0G9JEYuin7IoqCGhLiepDRtLlqf3iKra95pSKWm1vFAWS2ay00fVvOYMH1"
          ></stripe-pricing-table>
        </div>
      )}
    </div>
  )
}
