import React, { useRef } from "react"
import { Link, useHistory } from "react-router-dom"

import { useAuth } from "../../context/AuthContext"
import { telegram } from "../../utils/telegram"

function Login() {
  const emailRef = useRef()
  const passwordRef = useRef()
  const { login, signupWithGoogle } = useAuth()
  const [error, setError] = React.useState("")
  const [loading, setLoading] = React.useState(false)
  const history = useHistory()

  async function handleSubmit(e) {
    e.preventDefault()

    try {
      setError("")
      setLoading(true)
      await login(emailRef.current.value, passwordRef.current.value)
      history.push("/")
      setLoading(false)
    } catch {
      setTimeout(() => {
        setLoading(false)
        setError("Failed to login")
      }, 1000)

      telegram("Failed to login: " + emailRef.current.value)
    }
  }

  async function handleGoogleLogin() {
    try {
      setError("")
      setLoading(true)
      await signupWithGoogle()
      history.push("/")
    } catch {
      setError("Failed to login")
    }
    setLoading(false)
  }

  return (
    <section className="bg-gray-50 ">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <Link
          to="/"
          className="flex items-center mb-6 text-2xl font-semibold text-gray-900 "
        >
          Homepage
        </Link>
        <div className="w-full bg-white rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0 ">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 className="text-center text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl ">
              Log in
            </h1>
            <form
              className="space-y-4 md:space-y-6"
              action="#"
              onSubmit={handleSubmit}
            >
              <input
                type="email"
                name="email"
                id="email"
                className="border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 "
                placeholder="Email"
                required=""
                ref={emailRef}
                onChange={() => setError("")}
              />

              <input
                type="password"
                name="password"
                id="password"
                placeholder="Password"
                className="border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 "
                required=""
                ref={passwordRef}
                onChange={() => setError("")}
              />

              <div className="flex items-center justify-between">
                <Link
                  to="forgot-password"
                  className="text-sm font-medium text-blue-600 hover:underline "
                >
                  Forgot password?
                </Link>
                <Link
                  to="/register"
                  className="text-sm font-medium text-blue-600 hover:underline "
                >
                  Sign up
                </Link>
              </div>
              <button
                type="submit"
                disabled={loading}
                className="w-full text-white bg-blue-600 hover:bg-blue-700 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
              >
                {loading ? "Loading..." : "Log in"}
              </button>
              {error && (
                <div
                  className="p-4 mb-4 text-sm text-red-800 rounded-lg font-medium bg-red-50 "
                  role="alert"
                >
                  {error}
                </div>
              )}
            </form>
            <button
              type="button"
              className="w-full border hover:bg-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2 mb-2 justify-center"
              onClick={handleGoogleLogin}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="2443"
                height="2500"
                preserveAspectRatio="xMidYMid"
                viewBox="0 0 256 262"
                id="google"
                className="w-5 h-5 mr-2"
              >
                <path
                  fill="#4285F4"
                  d="M255.878 133.451c0-10.734-.871-18.567-2.756-26.69H130.55v48.448h71.947c-1.45 12.04-9.283 30.172-26.69 42.356l-.244 1.622 38.755 30.023 2.685.268c24.659-22.774 38.875-56.282 38.875-96.027"
                ></path>
                <path
                  fill="#34A853"
                  d="M130.55 261.1c35.248 0 64.839-11.605 86.453-31.622l-41.196-31.913c-11.024 7.688-25.82 13.055-45.257 13.055-34.523 0-63.824-22.773-74.269-54.25l-1.531.13-40.298 31.187-.527 1.465C35.393 231.798 79.49 261.1 130.55 261.1"
                ></path>
                <path
                  fill="#FBBC05"
                  d="M56.281 156.37c-2.756-8.123-4.351-16.827-4.351-25.82 0-8.994 1.595-17.697 4.206-25.82l-.073-1.73L15.26 71.312l-1.335.635C5.077 89.644 0 109.517 0 130.55s5.077 40.905 13.925 58.602l42.356-32.782"
                ></path>
                <path
                  fill="#EB4335"
                  d="M130.55 50.479c24.514 0 41.05 10.589 50.479 19.438l36.844-35.974C195.245 12.91 165.798 0 130.55 0 79.49 0 35.393 29.301 13.925 71.947l42.211 32.783c10.59-31.477 39.891-54.251 74.414-54.251"
                ></path>
              </svg>
              <p>Sign in with Google</p>
            </button>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Login
