import firebase from "firebase/compat/app"
import "firebase/compat/auth"
import "firebase/compat/firestore"
import "firebase/compat/analytics"
import "firebase/compat/storage"

import { getStripePayments } from "@stripe/firestore-stripe-payments"

const app = firebase.initializeApp({
  apiKey: "AIzaSyBqKFBEkealYPyDzvSkSQt68421L088ErY",
  authDomain: "wallpaperai.co",
  projectId: "wallpaper-e9519",
  storageBucket: "wallpaper-e9519.appspot.com",
  messagingSenderId: "883288972520",
  appId: "1:883288972520:web:c55fe497f56c2a5b41bd5d",
  measurementId: "G-QJ6HVM25HE",
})

firebase.analytics()

export const auth = app.auth()
export const firestore = app.firestore()
export const firebaseInstance = firebase
export const GoogleAuthProvider = firebase.auth.GoogleAuthProvider // Füge diese Zeile hinzu
export const storage = firebase.storage()

export default app

export const payments = getStripePayments(app, {
  productsCollection: "products",
  customersCollection: "users",
})

export const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp
